import React, { useEffect, useRef, useContext } from "react"
import { gsap } from "gsap"

import { useGameMachine, useGameContext } from "@hooks"
import { SiteContext } from "@context/siteContext"

export default React.memo(function Background({ id }) {
  const [gameState] = useGameMachine()
  const { audios } = useGameContext()
  const { isAudioActive } = useContext(SiteContext)
  //   const isGlobalAudioActive = useStore("isGlobalAudioActive")
  //   const isAppLoaded = useStore("isAppLoaded")
  const tw = useRef()
  const ref = useRef()

  const play = () => {
    if (isAudioActive) {
      ref.current.play()
      fadeIn()
    }
  }

  const pause = () => {
    ref.current.pause()
    fadeOut()
  }

  const fadeIn = () => {
    tw?.current?.kill()

    tw.current = gsap.fromTo(ref.current, { volume: 0 }, { volume: 1, duration: 2 })
  }

  const fadeOut = () => {
    tw?.current?.kill()

    tw.current = gsap.to(ref.current, {
      duration: 2,
      volume: 0,
    })
  }

  useEffect(() => {
    if (id) {
      audios.set(id, { ref: ref.current, play: play, pause: pause })
    }
  }, [])

  useEffect(() => {
    tw?.current?.kill()

    if (isAudioActive) {
      tw.current = gsap.fromTo(ref.current, { volume: 0 }, { volume: 1, duration: 2 })
    } else {
      tw.current = gsap.to(ref.current, {
        duration: 2,
        volume: 0,
      })
    }
  }, [isAudioActive])

  return <audio ref={ref} src="/audio/game/background.wav" loop />
})

import React, { useEffect, useRef, useState, useCallback } from "react"
import { useSpring, useSpringRef, useChain } from "@react-spring/core"
import { a } from "@react-spring/web"

import { useGameActor, useT, useMobileDevice } from "@hooks"

import { OverlayLayer, Cta, Frame } from "@gameDom"

import * as styles from "./index.module.scss"
import { css } from "@jsUtils"

const config = {
  mass: 2,
  tension: 200,
}

export default React.memo(function Single({ data, isCurrent }) {
  const fadeSpringRef = useSpringRef()
  const topSpringRef = useSpringRef()
  const videoSpringRef = useSpringRef()
  const ctaSpringRef = useSpringRef()
  const [fitH, setFitH] = useState()
  const [ratioVideo, setRatioVideo] = useState(1)

  const video = useRef()
  const [levelState, sendToLevel] = useGameActor("levelMachineRef")
  const t = useT("game")
  const isIos = useMobileDevice()?.apple?.device

  const isActive = levelState.matches("intro.moment") && isCurrent

  const onClick = () => {
    sendToLevel("START")
  }

  // FIT VERTICALLY OR HORIZONTALLY
  const fitVideo = useCallback(() => {
    const w = 0.7 * window.innerWidth
    const h = 0.45 * window.innerHeight
    const wr = w / h

    if (ratioVideo < wr) {
      setFitH(false)
    } else {
      setFitH(true)
    }
  }, [ratioVideo])

  useEffect(() => {
    fitVideo()

    window.addEventListener("resize", fitVideo)

    return () => {
      window.removeEventListener("resize", fitVideo)
    }
  }, [ratioVideo])

  useEffect(() => {
    // FORCE LOAD
    video.current.load()

    const updateRatio = () => {
      setRatioVideo(video.current.videoWidth / video.current.videoHeight)
    }

    video.current.addEventListener("loadedmetadata", updateRatio)

    return () => {
      if (video.current) video.current.removeEventListener("loadedmetadata", updateRatio)
    }
  }, [])

  const fadeSpring = useSpring({
    ref: fadeSpringRef,
    opacity: isActive ? 1 : 0,
    config: { duration: 400 },
  })

  const topSpring = useSpring({
    ref: topSpringRef,
    opacity: isActive ? 1 : 0,
    scale: isActive ? 1 : 0.8,
    config: config,
  })

  const videoSpring = useSpring({
    ref: videoSpringRef,
    opacity: isActive ? 1 : 0,
    scale: isActive ? 1 : 0.8,
    config: config,
  })

  const ctaSpring = useSpring({
    ref: ctaSpringRef,
    opacity: isActive ? 1 : 0,
    config: { duration: 600 },
  })

  const order = isActive
    ? [fadeSpringRef, topSpringRef, videoSpringRef, ctaSpringRef]
    : [ctaSpringRef, videoSpringRef, topSpringRef, fadeSpringRef]

  const timing = isActive ? [0.6, 0.8, 1.0, 1.6] : [0.1, 0.1, 0.1, 0.6]
  useChain(order, timing)

  useEffect(() => {
    if (isActive) {
      try {
        video?.current?.play()
      } catch {}
    } else {
      video?.current?.pause()
    }
  }, [isActive])

  return (
    <OverlayLayer styles={fadeSpring}>
      <a.div
        className={styles.root}
        style={{
          visibility: fadeSpring.opacity.to((v) => (v === 0 ? "hidden" : "visible")),
        }}
      >
        <a.div style={topSpring} className={styles.topContent}>
          <Frame>
            <div className={styles.detail}>
              <span>{data.year}</span>
              <img src={data.moment.teams[0].flag} />
              <span>VS</span>
              <img src={data.moment.teams[1].flag} />
              <span>{data.moment.category}</span>
            </div>
            <div className={styles.description}>{data.moment.description}</div>
          </Frame>
        </a.div>

        <a.div style={videoSpring}>
          <Frame>
            <div className={css(styles.videoContainer, fitH ? styles.fitH : styles.fitV)}>
              <video
                ref={video}
                src={data.moment.asset.src}
                playsInline
                preload="auto"
                controls
                loop
                muted={isIos}
              />
            </div>
          </Frame>
        </a.div>

        <a.div className={styles.ctaWrapper} style={ctaSpring}>
          <Cta onClick={onClick}>{t("game.momentCta")}</Cta>
        </a.div>
      </a.div>
    </OverlayLayer>
  )
})

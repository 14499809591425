import React from "react"
import { a } from "@react-spring/web"
import * as css from "./index.module.scss"

export default React.memo(function OverlayLayer({ children, styles = {} }) {
  return (
    <a.div style={styles} className={css.root}>
      {children}
    </a.div>
  )
})

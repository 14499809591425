import React, { useEffect, useRef } from "react"
import { a } from "@react-spring/three"
import { useSpring } from "@react-spring/core"
import { useFrame } from "@react-three/fiber"

import { useGameActor, useGameAsset } from "@hooks"

import Line from "./Line"

import { config } from "@gameData"

export default React.memo(function Aim() {
  const [state] = useGameActor("levelMachineRef")
  const { misc } = config
  const map = useGameAsset("aim-cross")

  const ref = useRef()

  const [{ scale }, set] = useSpring(() => ({
    config: {
      mass: 16,
      friction: 120,
    },
    scale: 0,
  }))

  useEffect(() => {
    set({
      scale: state.matches("playing.aiming") ? 3 : 0,
    })
  }, [state.value])

  useFrame(() => {
    if (state.matches("playing.aiming")) {
      ref.current.position.x = state.context.aimPosition[0]
      ref.current.position.y = state.context.aimPosition[1]
    }
  })

  return (
    <>
      <a.mesh ref={ref} position-z={-misc.shot.distance + 0.03} scale={scale} renderOrder={20}>
        <planeBufferGeometry args={[0.2, 0.2, 1, 1]} />
        <meshBasicMaterial transparent map={map} />
      </a.mesh>
      <Line />
    </>
  )
})

import React, { useEffect } from "react"
import { gsap } from "gsap"
import { useFrame } from "@react-three/fiber"

import { useGameMachine, useGameActor } from "@hooks"

import { useMediaQuery } from "@helpers/breakpoints"

export default function useCameraChoregraphy({ camera, config, updateLookAt }) {
  const [state, send] = useGameMachine()
  const [levelState] = useGameActor("levelMachineRef")
  const isAiming = levelState.matches("playing.aiming")

  const { context } = levelState
  const { normalizedAimPosition } = context

  const { y, z } = useMediaQuery(config)

  const onOpening = () => {
    const tl = gsap.timeline()

    tl
      //
      .to(camera.position, {
        duration: 4,
        z: z,
        y: y,
        delay: 0,
        ease: "Power3.easeInOut",
        onUpdate: () => {
          updateLookAt()
        },
      })
      .call(send, ["DONE"], 3)
  }

  const onGameWon = () => {
    gsap.to(camera.position, {
      duration: 1.8,
      z: z - 4,
      delay: 0.3,
      ease: "Power1.easeInOut",
      onUpdate: () => {
        updateLookAt()
      },
    })
  }

  const onResetAfterAim = () => {
    const tl = gsap.timeline({ delay: 0.8 })

    tl.to(camera.position, {
      duration: 2.5,
      x: 0,
      y: y,
      z: z,
      ease: "Power4.easeInOut",
      onUpdate: () => {
        updateLookAt()
      },
    })
  }

  const onStartAim = () => {
    gsap.to(camera.position, {
      duration: 1,
      z: "-=1.5",
      ease: "Power1.easeInOut",
    })
  }

  useEffect(() => {
    if (state.matches("opening.animating")) {
      onOpening()
    }
    if (state.matches("gameWon")) {
      onGameWon()
    }
  }, [state])

  useEffect(() => {
    if (levelState.matches("playing.aiming")) {
      onStartAim()
    }

    if (levelState.matches("outro")) {
      onResetAfterAim()
    }
  }, [levelState])

  useFrame(() => {
    if (isAiming) {
      camera.position.x = -normalizedAimPosition[0] * 0.8
      camera.position.y = y - normalizedAimPosition[1] * 0.2
      updateLookAt()
    }
  })
}

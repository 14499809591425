import React from "react"

import {
  Lights,
  Field,
  Background,
  Sponsors,
  Ball,
  Aim,
  VisualTarget,
  GoalCelebration,
  Countdown,
  Trophy,
} from "@gameWebgl"

export default React.memo(function Stage() {
  return (
    <>
      <Lights />
      <Field />
      <Sponsors />
      <Background />
      <Ball />
      <Aim />
      <VisualTarget />
      <GoalCelebration />
      <Countdown />
      <Trophy />
    </>
  )
})

import React, { useRef, useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { gsap } from "gsap"

import { useGameMachine } from "@hooks"

import { useMediaQuery } from "@helpers/breakpoints"

import useCameraChoregraphy from "./useCameraChoregraphy"

const CONFIG_PORTRAIT = {
  xs: { fov: 73, y: 5.3, lookAtY: 3.1, z: 15.2 },
  sm: { fov: 60, y: 3, lookAtY: 2.4, z: 15 },
  md: { fov: 60, y: 3, lookAtY: 2.4, z: 15 },
  lg: { fov: 40, y: 3, lookAtY: 2.4, z: 15 },
  xl: { fov: 50, y: 3.5, lookAtY: 2, z: 12 },
}

const CONFIG_LANDSCAPE = {
  xs: { fov: 80, y: 5.3, lookAtY: 3.1, z: 15 },
  sm: { fov: 60, y: 3, lookAtY: 2.4, z: 15 },
  md: { fov: 40, y: 3, lookAtY: 2.4, z: 15 },
  lg: { fov: 40, y: 3, lookAtY: 2.4, z: 15 },
  xl: { fov: 50, y: 3.5, lookAtY: 2, z: 12 },
}

export default React.memo(function Camera() {
  const [state, send] = useGameMachine()
  const camera = useRef()
  const { size, set } = useThree()
  const isLandscape = size.width > size.height
  const config = isLandscape ? CONFIG_LANDSCAPE : CONFIG_PORTRAIT

  const { fov, y, lookAtY, z } = useMediaQuery(config)

  const updateLookAt = () => {
    if (camera.current) camera.current.lookAt(0, lookAtY, -11)
  }

  useEffect(function onMount() {
    if (camera.current) {
      camera.current.updateProjectionMatrix()

      camera.current.position.set(0, y + 8, z * 20)

      updateLookAt()

      set({ camera: camera.current })
    }
  }, [])

  useEffect(() => {
    if (camera.current) {
      updateLookAt()
      camera.current.updateProjectionMatrix()
    }
  }, [fov, y, lookAtY, z])

  useCameraChoregraphy({
    camera: camera.current,
    config,
    updateLookAt,
  })

  return (
    <>
      <perspectiveCamera
        ref={camera}
        aspect={[size.width / size.height]}
        fov={fov}
        name="MainCamera"
        onUpdate={(self) => self.updateMatrix()}
      />
    </>
  )
})

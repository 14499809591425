import React from "react"

export default React.memo(function Background() {
  return (
    <mesh position-z={-20} scale={[100, 40, 1]}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial color={0x000000} />
    </mesh>
  )
})

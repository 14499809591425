import React from "react"
import { Canvas } from "@gameWebgl"

import * as style from "./index.module.scss"

export default React.memo(function CanvasContainer() {
  return (
    <div className={style.wrapper}>
      <Canvas />
    </div>
  )
})

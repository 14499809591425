import React, { useRef, useState, useEffect } from "react"

import { useGameActor } from "@hooks"

import Single from "./Single"

export default React.memo(function Countdown() {
  const [current, setCurrent] = useState(4)
  const [state, send] = useGameActor("levelMachineRef")
  const isActive = state.matches("playing.initialCountdown")
  const interval = useRef()

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        interval.current = setInterval(() => {
          setCurrent((v) => v - 1)
          send("TICK")
        }, 1000)
      }, 2000)
    }
  }, [isActive])

  useEffect(() => {
    if (current === 0) {
      clearInterval(interval.current)
      setCurrent(4)
      send("DONE")
    }
  }, [current])

  return (
    <>
      <Single isActive={current === 1} assetKey={"countdown-1"} />
      <Single isActive={current === 2} assetKey={"countdown-2"} />
      <Single isActive={current === 3} assetKey={"countdown-3"} />
    </>
  )
})

import React from "react"

import { Onboarding, Moments, EndLevel, LevelTopInfo } from "@gameDom"

import { useGameMachine } from "@hooks"

import * as styles from "./index.module.scss"

export default React.memo(function LevelContainer() {
  const [state] = useGameMachine()
  const isAppLoaded = state.context.isAppLoaded

  return (
    <div className={styles.root}>
      {isAppLoaded && (
        <>
          <Onboarding />
          <Moments />
          <EndLevel />
          <LevelTopInfo />
        </>
      )}
    </div>
  )
})

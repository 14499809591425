import React, { useRef, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import { a } from "@react-spring/three"
import { useSpring } from "@react-spring/core"

import { useGameActor, useGameContext } from "@hooks"

import { config } from "@gameData"

export default React.memo(function Line() {
  const line = useRef()
  const { aim: aimConfig, misc } = config

  const { threeObjs } = useGameContext()

  const [state] = useGameActor("levelMachineRef")
  const { context } = state

  const { helpers } = threeObjs
  const { shootingCurve } = helpers

  const needsUpdate = state.matches("playing.aiming")

  const [{ opacity }, set] = useSpring(() => ({
    opacity: 0,
    config: {
      duration: 1000,
    },
  }))

  useEffect(() => {
    if (state.matches("playing.aiming")) {
      set({ opacity: 1 })
    } else {
      set({ opacity: 0 })
    }
  }, [state])

  const bendPoints = () => {
    // SET CURVE
    shootingCurve.points.forEach((el, i) => {
      const p = i / (shootingCurve.points.length - 1)

      el.set(
        context.aimPosition[0] * p +
          Math.sin(p * Math.PI) * (context.aimPosition[0] * aimConfig.curve.factor),
        context.aimPosition[1] * p + 0.15 * (1 - p),
        p * -misc.shot.distance,
      )
    })

    // GET NEW POINTS
    let pts = shootingCurve.getSpacedPoints(shootingCurve.points.length)

    // UPDATE LINE
    pts.forEach((el, i) => {
      line.current.geometry.attributes.position.setXYZ(i, el.x, el.y, el.z)
    })

    line.current.geometry.needsUpdate = true
    line.current.geometry.attributes.position.needsUpdate = true
  }

  useFrame(() => {
    if (needsUpdate) {
      bendPoints()
    }
  })

  return (
    <line ref={line} renderOrder={30}>
      <bufferGeometry onUpdate={(self) => self.setFromPoints(shootingCurve.points)} />
      <a.lineBasicMaterial color={0xffffff} transparent opacity={opacity} />
    </line>
  )
})

import React, { useEffect, useState, useRef } from "react"

import * as styles from "./index.module.scss"
import { css } from "@jsUtils"

import { useGameMachine, useT } from "@hooks"

import { Html } from "@dom"

export default React.memo(function Team({ data }) {
  const [state] = useGameMachine()

  const { currentLevel } = state.context
  const isOnboarding = currentLevel === 0

  const t = useT("game")
  return (
    <>
      <div className={css(styles.root, isOnboarding && styles.isOnboarding)}>
        {isOnboarding && <Html>{t("game.timer.onboarding")}</Html>}
        {!isOnboarding && (
          <>
            <div>{data.name}</div>
            <div>
              <img src={data.flag} />
            </div>
          </>
        )}
      </div>
    </>
  )
})

import React from "react"
import { a } from "@react-spring/web"

import * as styles from "./index.module.scss"

import { useT } from "@hooks"

import Single from "./Single"

export default React.memo(function LevelsIndicator({ spring }) {
  const t = useT("game")
  const content = t("levels")

  const nodes = content.map((el, i) => {
    return <Single data={el} key={i} id={i} />
  })

  return (
    <a.div style={spring} className={styles.root}>
      {nodes}
    </a.div>
  )
})

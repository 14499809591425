import React from "react"
import { a } from "@react-spring/three"
import { useSpring } from "@react-spring/core"

export default React.memo(function VisualTarget({ map, i, isActive }) {
  const spring = useSpring({
    config: {
      mass: 3.1,
      tension: 234,
      friction: 30,
    },
    scale: isActive ? 3 : 0,
    delay: 100 * i,
  })

  return (
    <a.mesh position-z={0.01 * i} scale={spring.scale} renderOrder={10 + i}>
      <planeBufferGeometry args={[0.4, 0.4, 1, 1]} />
      <meshBasicMaterial transparent map={map} />
    </a.mesh>
  )
})

import React, { useEffect } from "react"
import { RepeatWrapping } from "three"

import { useFrame } from "@react-three/fiber"

import { useGameAsset } from "@hooks"

export default React.memo(function Sponsors() {
  const map = useGameAsset("sponsors")
  const height = 3

  useEffect(() => {
    map.wrapS = map.wrapT = RepeatWrapping
    map.repeat.x = 11.8
    map.needsUpdate = true
  }, [map])

  useFrame(() => {
    map.offset.x += 0.001
  })

  return (
    <mesh position-y={height * 0.5} position-z={-16} scale={[100, height, 1]}>
      <planeBufferGeometry args={[1, 1]} />
      <meshStandardMaterial color={0x505050} map={map} />
    </mesh>
  )
})

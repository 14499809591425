import React from "react"
import * as styles from "./index.module.scss"

import { a } from "@react-spring/web"

export default React.memo(function Cta({ spring, children, onClick }) {
  return (
    <a.div style={spring} className={styles.root} onClick={onClick}>
      <div className={styles.border} />
      {children}
    </a.div>
  )
})

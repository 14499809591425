import React from "react"
import { Canvas } from "@react-three/fiber"
import { Context as ThreeAssetsContext } from "@context/game/threeAssets"

import { Camera, Stage } from "@gameWebgl"

import { useContextBridge, useGameMachine } from "@hooks"

import { GameContext } from "@context/game"

export default function Cvs() {
  const ContextBridge = useContextBridge(GameContext)

  const [state] = useGameMachine()
  const isAppLoaded = state.context.isAppLoaded

  return (
    <Canvas dpr={[1, 2]} gl={{ antialias: true, alpha: false }} shadows={true}>
      <ContextBridge>
        <ThreeAssetsContext>
          <Camera />
          {isAppLoaded && <Stage />}
        </ThreeAssetsContext>
      </ContextBridge>
    </Canvas>
  )
}

import React, { useEffect, useRef } from "react"

import { Color } from "three"
import { gsap } from "gsap"

import { useGameAsset, useGameActor } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

import vertexShader from "./shaders/index.vert"
import fragmentShader from "./shaders/index.frag"

export default React.memo(function Single({ isActive, assetKey }) {
  const ref = useRef()
  const [state] = useGameActor("levelMachineRef")
  const map = useGameAsset(assetKey)

  const z = useMediaQuery({
    xs: -2,
    md: -8,
  })

  const args = {
    vertexShader,
    fragmentShader,
    uniforms: {
      uTexture: { value: map },
      uOpacity: { value: 0 },
      uGradientA: { value: new Color("#EAEAF0") },
      uGradientB: { value: new Color("#A8A6A9") },
      uColorPositiveA: { value: new Color("#FFE600") },
      uColorPositiveB: { value: new Color("#ff0000") },
      uColorNegativeA: { value: new Color("#00E5FF") },
      uColorNegativeB: { value: new Color("#0000FF") },
    },
  }

  useEffect(() => {
    if (isActive) {
      let tl = gsap.timeline()

      tl.set(args.uniforms.uOpacity, { value: 0 })
        .fromTo(
          ref.current.scale,
          { x: 1.6, y: 1.6 },
          { duration: 2, x: 0.5, y: 0.5, ease: "Power3.easeInOut" },
        )
        .to(args.uniforms.uOpacity, { duration: 1, value: 1 }, 0)
        .to(args.uniforms.uOpacity, { duration: 1, value: 0 }, 1)

      tl.duration(0.8)
    }
  }, [isActive])

  useEffect(() => {}, [state.value])

  return (
    <mesh ref={ref} position-y={3.5} position-z={z} renderOrder={30} visible={isActive}>
      <planeBufferGeometry args={[4, 4, 1, 1]} />
      <shaderMaterial args={[args]} uniforms-uTexture-value={map} transparent />
    </mesh>
  )
})

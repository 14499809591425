import React from "react"

import { Audio } from "@gameDom"

import Background from "./Background"

export default React.memo(function Audios() {
  return (
    <>
      <Background id={"background"} volume={0} />
      <Audio src="/audio/game/referee.wav" eventTrigger="playing.idle" />
      <Audio src="/audio/game/score.wav" eventTrigger="outro.celebrating" />
      <Audio src="/audio/game/trombone.mp3" eventTrigger="outro.fail" />
      <Audio src="/audio/game/won.mp3" eventTrigger="gameWon" />
      <Audio src="/audio/game/kick.wav" eventTrigger="playing.shooting" />
      <Audio
        src="/audio/game/beep.wav"
        ctxTrigger={(g, l) => l.secondsCountdown > 0 && l.secondsCountdown < 4}
      />
    </>
  )
})

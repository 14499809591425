import React, { useEffect, useRef, useContext } from "react"
import { gsap } from "gsap"

import { useGameContext, useGameMachine, useGameActor } from "@hooks"
import { SiteContext } from "@context/siteContext"

export default React.memo(function Audio({ eventTrigger, ctxTrigger, preload, ...props }) {
  const { isAudioActive } = useContext(SiteContext)
  const [gameState] = useGameMachine()
  const [levelState] = useGameActor("levelMachineRef")

  const gameCtx = gameState.context
  const levelCtx = levelState.context

  const prld = preload || gameState.context.isAppLoaded ? "auto" : "metadata"

  const ref = useRef()
  const tw = useRef()

  const play = () => {
    ref.current.currentTime = 0
    ref.current.play()
  }

  useEffect(() => {
    if (eventTrigger) {
      if (gameState.matches(eventTrigger) || levelState.matches(eventTrigger)) {
        play()
      }
    }
  }, [gameState, levelState])

  useEffect(() => {
    if (ctxTrigger) {
      if (ctxTrigger(gameCtx, levelCtx)) {
        play()
      }
    }
  }, [gameCtx, levelCtx])

  useEffect(() => {
    tw?.current?.kill()

    if (isAudioActive) {
      tw.current = gsap.fromTo(ref.current, { volume: 0 }, { volume: 1, duration: 2 })
    } else {
      tw.current = gsap.to(ref.current, {
        duration: 2,
        volume: 0,
      })
    }
  }, [isAudioActive])

  return <audio ref={ref} {...props} preload={prld} />
})

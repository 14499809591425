import React from "react"

import * as styles from "./index.module.scss"
import { css } from "@jsUtils"

export default React.memo(function Frame({ style, children }) {
  return (
    <div className={css(styles.root, style)}>
      <img src={"/images/description-cross.svg"} className={css(styles.cross, styles.tl)} />
      <img src={"/images/description-cross.svg"} className={css(styles.cross, styles.tr)} />
      <img src={"/images/description-cross.svg"} className={css(styles.cross, styles.br)} />
      <img src={"/images/description-cross.svg"} className={css(styles.cross, styles.bl)} />
      {children}
    </div>
  )
})

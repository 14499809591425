import React, { useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { gsap } from "gsap"

import { useGameAsset, useGameMachine } from "@hooks"

import { config } from "@gameData"

export default React.memo(function Trophy() {
  const [state] = useGameMachine()
  const { gl } = useThree()

  const { scene } = useGameAsset("trophy")
  const { trophy } = config
  const { scale, position } = trophy

  const yShift = 20

  useEffect(() => {
    scene.traverse((el) => {
      if (el.isMesh) {
        el.castShadow = true
        el.material.metalness = 0.3
        // if(el.material.map)
        if (el.material.aoMap) gl.initTexture(el.material.aoMap)
        if (el.material.map) gl.initTexture(el.material.map)
        if (el.material.metalnessMap) gl.initTexture(el.material.metalnessMap)
        if (el.material.roughnessMap) gl.initTexture(el.material.roughnessMap)
      }
    })
  }, [])

  useEffect(() => {
    if (state.matches("gameWon")) {
      const duration = 1.6
      let tl = gsap.timeline()

      tl.set(scene.position, { y: yShift })
        .set(scene.rotation, { y: 0 })
        .to(scene.position, {
          duration,
          y: 0,
          ease: "Power3.easeOut",
        })
        .to(
          scene.rotation,
          {
            duration,
            y: 6.28,
            ease: "Power3.easeOut",
          },
          0,
        )
    }
  }, [state])

  return (
    <primitive
      object={scene}
      scale={[scale, scale, scale]}
      position={[position[0], position[1] + yShift, position[2]]}
    />
  )
})

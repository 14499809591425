import React, { useEffect, useContext } from "react"

import { Audios, GameContainer, CanvasContainer, Landing, Level, EndGame } from "@gameDom"
import { useGameMachine, useGameContext, useMobileDevice } from "@hooks"
import { SiteContext } from "@context/siteContext"
import { OverlayContent, Footer } from "@dom"
import Helmet from "react-helmet"

export default function Game() {
  // LOADING LOGIC
  const { actions, transition, isAudioSupported, isAudioActive } = useContext(SiteContext)
  // const { isAudioSupported, setIsAudioSupported, isAudioActive } = useGameContext()

  // LOADING LOGIC
  const [state, send] = useGameMachine()
  const isTouch = useMobileDevice()?.any

  useEffect(() => {
    if (state.matches("loaded")) {
      setTimeout(() => {
        actions.setReady(true)
      }, 900)
    }
  }, [state])

  useEffect(() => {
    if (!transition) actions.setTransition(true)
    // HIJACK THE 100VH (min-height: -webkit-fill-available; not ready for prod)
    const onResize = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    onResize()
    window.addEventListener("resize", onResize)
    actions.setBackground("black")

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Concacaf Experience60 - Skills Challenge</title>
        <meta
          name="description"
          content="An interactive experience celebrating the greatest moments of our Concacaf’s first 60 years – offering you a chance to relive them, put yourself in them, and win prizes predicting what will happen next."
        />
        <meta
          name="og:description"
          content="An interactive experience celebrating the greatest moments of our Concacaf’s first 60 years – offering you a chance to relive them, put yourself in them, and win prizes predicting what will happen next."
        />
        <meta name="og:title" content="Concacaf Experience 60" />
        <meta property="og:image" content={"https://concacaf60.com/social.jpg"} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta name="twitter:image" content={"https://concacaf60.com/social.jpg"} />
        <meta name="twitter:title" content="Concacaf Experience 60 - Skills Challenge" />
        <meta
          name="twitter:image:alt"
          content="An interactive experience celebrating the greatest moments of Concacaf's first 60 years"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Audios />
      <GameContainer>
        <OverlayContent
          mode={"game"}
          isAudioSupported={isAudioSupported}
          setIsAudioSupported={actions.setIsAudioSupported}
          isAudioActive={isAudioActive}
          setIsAudioActive={actions.setIsAudioActive}
        />
        <CanvasContainer />
        <Landing />
        <Level />
        <EndGame />
      </GameContainer>
      {!isTouch && <Footer />}
    </>
  )
}

import React, { useEffect, useState } from "react"
import { useSpring, useTransition, useChain, useSpringRef } from "@react-spring/core"
import { a } from "@react-spring/web"

import { useGameContext, useGameActor, useT } from "@hooks"

import { Cta, OverlayLayer } from "@gameDom"
import * as styles from "./index.module.scss"

import Instructions from "./Instructions"

export default React.memo(function Onboarding() {
  const loopRef = useSpringRef()
  const scaleRef = useSpringRef()
  const mountRef = useSpringRef()
  const { unprojectedBallPosition } = useGameContext()
  const [levelState, sendToLevel] = useGameActor("levelMachineRef")
  const [position, setPosition] = useState({ x: -100, y: -100 })

  const [isActive, setActive] = useState(false)
  const isLevelOnboarding = levelState.matches("intro.onboarding")

  const t = useT("game")

  const onClick = () => {
    sendToLevel("START")
  }

  useEffect(() => {
    setTimeout(
      () => {
        setActive(isLevelOnboarding)
      },
      isLevelOnboarding ? 800 : 0,
    )
  }, [isLevelOnboarding])

  useEffect(() => {
    setPosition({
      x: unprojectedBallPosition.x * window.innerWidth,
      y: unprojectedBallPosition.y * window.innerHeight,
    })
  }, [unprojectedBallPosition])

  const loopSpring = useSpring({
    ref: loopRef,
    from: { x: "-70%", rotate: 10 },
    to: async (next) => {
      while (isActive) {
        await next({ x: "-30%", rotate: -10, delay: 200 })
        await next({ x: "-70%", rotate: 10, delay: 200 })
      }
    },
  })

  const scaleSpring = useSpring({
    ref: scaleRef,
    from: { scale: 0 },
    scale: isActive ? 1 : 0,
    delay: isActive ? 1000 : 200,
    config: {
      mass: 4,
      tension: 300,
    },
  })

  const mountSpring = useSpring({
    ref: mountRef,
    opacity: isActive ? 1 : 0,
    delay: isActive ? 700 : 0,
    config: {
      duration: 1000,
    },
  })

  const order = isActive ? [mountRef, scaleRef, loopRef] : [loopRef, scaleRef, mountRef]
  useChain(order, isActive ? [0, 0.2, 1] : [0, 0, 0])

  return (
    <OverlayLayer styles={mountSpring}>
      <a.div
        className={styles.root}
        style={{
          ...mountSpring,
          visibility: mountSpring.opacity.to((v) => (v === 0 ? "hidden" : "visible")),
        }}
      >
        <a.div
          className={styles.icon}
          style={{
            ...loopSpring,
            ...scaleSpring,
            left: position.x + "px",
            top: position.y + "px",
          }}
        >
          <img src={"/images/icons/finger-gesture.svg"} />
        </a.div>

        <Instructions isActive={isActive} position={position} />

        <div className={styles.ctaWrapper}>
          <Cta onClick={onClick}>{t("game.momentCta")}</Cta>
        </div>
      </a.div>
    </OverlayLayer>
  )
})

import React, { useEffect } from "react"
import { useSprings } from "@react-spring/core"
import { a } from "@react-spring/web"

import { useT, useMobileDevice } from "@hooks"

import * as styles from "./index.module.scss"

export default React.memo(function Instructions({ isActive, position }) {
  const t = useT("game")
  const version = useMobileDevice()?.any ? "mobile" : "desktop"

  const content = t("game.onboarding")[version]

  const [springs, api] = useSprings(3, (i) => ({
    from: { opacity: 0, y: 10 },
    delay: i * 4000,
    onRest: (s) => {
      if (s.value.opacity === 0 && i === 2) {
        startLoop()
      }
    },
  }))

  const startLoop = async () => {
    await api.start((i) => ({
      to: async (next, cancel) => {
        await next({ opacity: 0, y: 10, immediate: true, delay: 4000 * i })
        await next({ opacity: 1, y: 0, immediate: false })
        await new Promise((res) => setTimeout(res, 2000))
        await next({ opacity: 0, y: -10 })
      },
    }))
  }

  useEffect(() => {
    if (isActive) {
      setTimeout(async () => {
        startLoop()
      }, 2000)
    } else {
      api.stop(true)
    }
  }, [isActive])

  return (
    <div
      className={styles.root}
      style={{
        left: position.x + "px",
        top: position.y + "px",
      }}
    >
      {springs.map((style, i) => (
        <a.div
          style={{
            transform: style.y.to((v) => `translateY(${v}px) translateY(-50%)`),
            ...style,
          }}
          key={i}
        >
          {content[i]}
        </a.div>
      ))}
    </div>
  )
})

import React, { useEffect, useState, useRef } from "react"
import * as styles from "./index.module.scss"

import { useGameActor } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

export default React.memo(function Timer({ data }) {
  const [state, send] = useGameActor("levelMachineRef")
  const [time, setTime] = useState(10000)
  const stt = useRef(undefined)
  const delta = 113 // ms

  const dataTime = data.game.time

  const isMobile = useMediaQuery({
    xs: true,
    sm: false,
  })

  const parseTime = () => {
    let s = Math.floor(time / 1000)
    s = s >= 10 ? s : "0" + s

    let ms = time % 1000
    if (ms < 10) {
      ms = "00" + ms
    } else if (ms < 100) {
      ms = "0" + ms
    }

    const n = isMobile ? 2 : 3
    ms = ms.toString().slice(0, n)

    return `00 : ${s} : ${ms}`
  }

  const startCountdown = () => {
    stt.current = setInterval(() => {
      setTime((v) => {
        if (v - delta > 0) {
          return v - delta
        } else {
          endCountdown()
          return 0
        }
      })
    }, delta)
  }

  const endCountdown = () => {
    send("TIME_OUT")
    stopCountdown()
  }

  const stopCountdown = () => {
    clearInterval(stt.current)
  }

  useEffect(() => {
    if (state.matches("intro") || state.matches("playing.initialCountdown")) {
      setTime(dataTime * 1000)
    }

    if (state.matches("playing.idle")) {
      startCountdown()
    }

    if (state.matches("outro")) {
      stopCountdown()
    }
  }, [state])

  return (
    <>
      <div className={styles.root}>
        <h2>{parseTime(time)}</h2>
      </div>
    </>
  )
})

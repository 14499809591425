import React, { useEffect } from "react"
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/three"
import { Color } from "three"

import { useGameAsset, useGameActor } from "@hooks"

import vertexShader from "./shaders/index.vert"
import fragmentShader from "./shaders/index.frag"

import { config } from "@gameData"

export default React.memo(function GoalCelebration() {
  const [state, send] = useGameActor("levelMachineRef")
  const map = useGameAsset("goal-celebration")

  const { width, height } = config.goal.size
  const ratio = 1 / 4

  const isActive = state.matches("outro.celebrating")

  const args = {
    vertexShader,
    fragmentShader,
    uniforms: {
      uTexture: { value: map },
      uOpacity: { value: 0 },
      uGradientA: { value: new Color("#EAEAF0") },
      uGradientB: { value: new Color("#A8A6A9") },
      uColorPositiveA: { value: new Color("#FFE600") },
      uColorPositiveB: { value: new Color("#ff0000") },
      uColorNegativeA: { value: new Color("#00E5FF") },
      uColorNegativeB: { value: new Color("#0000FF") },
    },
  }

  const spring = useSpring({
    config: {
      mass: 4,
      tension: 234,
      friction: 30,
    },
    scale: isActive ? [width, width * ratio, 1] : [width * 0.5, width * 0.5 * ratio, 1],
    opacity: isActive ? 1 : 0,
  })

  useEffect(() => {
    let stt = null
    if (state.matches("outro.celebrating")) {
      clearTimeout(stt)
      stt = setTimeout(() => {
        send("DONE")
      }, 3000)
    }

    if (state.matches("outro.leavingCelebration")) {
      clearTimeout(stt)
      stt = setTimeout(() => {
        send("DONE")
      }, 800)
    }
  }, [state.value])

  return (
    <a.mesh
      position-y={height * 0.5}
      position-z={-11}
      scale={spring.scale}
      renderOrder={30}
      matrixAutoUpdate={false}
      onUpdate={(self) => self.updateMatrix()}
    >
      <planeBufferGeometry args={[1, 1, 2, 2]} />
      <a.shaderMaterial
        args={[args]}
        uniforms-uTexture-value={map}
        uniforms-uOpacity-value={spring.opacity}
        transparent
      />
    </a.mesh>
  )
})

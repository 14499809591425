import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Vector3 } from "three"
import { useThree } from "@react-three/fiber"

import { useGameMachine, useGameActor, useGameContext, useGameAsset } from "@hooks"

import useAiming from "./useAiming"

import { config } from "@gameData"

const V3 = new Vector3()
const Y_POS = 0.3
const BALL_SCALE = 2.9

export default React.memo(function Ball() {
  const ballRef = useRef()
  const group = useRef()
  const bounds = [100, 80]
  const { scene } = useGameAsset("ball")

  const [gameState] = useGameMachine()
  const [state, send] = useGameActor("levelMachineRef")
  const { normalizedAimPosition } = state.context

  const { threeObjs, actions } = useGameContext()
  const { setUprojectedBallPosition } = actions
  const { helpers } = threeObjs
  const { shootingCurve } = helpers

  const { ball } = config

  const { camera, gl } = useThree()

  const unprojectBall = (v, camera) => {
    v.project(camera)

    setUprojectedBallPosition({
      x: v.x * 0.5 + 0.5,
      y: -v.y * 0.5 + 0.5,
    })
  }

  useEffect(function onMount() {
    group.current.rotation.order = "YXZ"

    scene.traverse((el) => {
      if (el.isMesh) {
        el.castShadow = true
      }
    })
  }, [])

  const resetBall = () => {
    const tl = gsap.timeline()
    const g = group.current
    const r = ballRef.current

    tl
      //
      .to(r.scale, { duration: 0.4, x: 0, y: 0, z: 0 })
      .set(r.rotation, { x: 0, y: 0, z: 0 })
      .set(g.position, { x: 0, z: 0 })
      .addLabel("enter", "+=0.5")
      .set(g.position, { y: 2 }, "enter")
      .to(r.scale, { duration: 0.4, x: BALL_SCALE, y: BALL_SCALE, z: BALL_SCALE }, "enter")
      .to(g.position, { duration: 0.8, y: 0, ease: "bounce.out" }, "enter")
  }

  useEffect(() => {
    // RESET BALL
    if (
      (state.matches("disable") && state?.history?.value) ||
      (state.matches("playing.initialCountdown") && state?.history?.matches("outro")) ||
      state.matches("intro.onboarding")
    ) {
      resetBall()
    }

    if (state.matches("intro.onboarding")) {
      setTimeout(() => {
        unprojectBall(group.current.getWorldPosition(V3), camera)
      }, 700)
    }
  }, [state.value])

  const shoot = () => {
    const moveOnCurve = (p) => {
      shootingCurve.getPointAt(p, V3)
      group.current.position.x = V3.x
      group.current.position.y = V3.y
      group.current.position.z = V3.z
    }

    let tl = gsap.timeline({
      onComplete: () => {
        send("SHOT")
      },
    })

    tl
      //
      .to(ballRef.current.rotation, {
        duration: 0.55,
        y: ball.spinFactor.y * normalizedAimPosition[0],
        x: -ball.spinFactor.x * normalizedAimPosition[1],
        ease: "Power2.easeOut",
        onUpdate: function () {
          moveOnCurve(this.progress())
        },
      })
  }

  const [bind] = useAiming({
    bounds,
    onEndCb: shoot,
  })

  const handleCursor = (v) => {
    if (state.matches("playing.idle")) {
      document.body.style.cursor = v ? "grab" : "auto"
    } else {
      document.body.style.cursor = "auto"
    }
  }

  return (
    <>
      <group ref={group}>
        <group scale={[0, 0, 0]} position-y={Y_POS} ref={ballRef}>
          <primitive object={scene} position-y={-0.115}></primitive>
        </group>
        <mesh
          visible={false}
          name={"ball-drag-padding"}
          {...bind()}
          onPointerEnter={() => handleCursor(true)}
          onPointerLeave={() => handleCursor(false)}
        >
          <planeBufferGeometry args={[1.5, 1.5, 1, 1]} />
          <meshBasicMaterial color={0xff0000} />
        </mesh>
      </group>
    </>
  )
})

import React from "react"
import * as styles from "./index.module.scss"

import LevelCountdown from "./LevelCountdown"
import ShotFailed from "./ShotFailed"

export default React.memo(function LevelTopInfo() {
  return (
    <>
      <div className={styles.root}>
        <LevelCountdown />
        <ShotFailed />
      </div>
    </>
  )
})

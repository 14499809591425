import React, { useEffect, useState, useRef } from "react"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import * as styles from "./index.module.scss"

import { useGameMachine, useGameActor, useT } from "@hooks"

import { Frame } from "@gameDom"
import Timer from "./Timer"
import Team from "./Team"

export default React.memo(function LevelCountdown() {
  const [gameState] = useGameMachine()
  const [levelState] = useGameActor("levelMachineRef")
  const isActive = levelState.matches("playing") || levelState.matches("outro.celebrating")

  const { levels, currentLevel } = gameState.context
  const data = levels[currentLevel]

  const t = useT("game")
  const contentLevels = t("levels")
  const { moment } = contentLevels[currentLevel]

  const getSpringDelay = () => {
    if (levelState.matches("outro.celebrating")) {
      return 700
    } else if (levelState.matches("playing")) {
      return levelState?.history?.matches("intro") ? 1500 : 700
    } else {
      return 0
    }
  }

  const transitions = useTransition(isActive, {
    from: { opacity: 0, scale: 0.9, x: "-50%" },
    enter: { opacity: 1, scale: 1, x: "-50%" },
    leave: { opacity: 0, scale: 0.9, x: "-50%" },
    delay: getSpringDelay(),
    config: {
      mass: 2,
      tension: 351,
    },
  })

  return (
    <>
      {transitions(
        (springStyles, item) =>
          item && (
            <a.div style={springStyles} className={styles.root}>
              <Frame style={styles.content}>
                <Team data={moment.teams[0]} />
                <Timer data={data} />
                <Team data={moment.teams[1]} />
              </Frame>
            </a.div>
          ),
      )}
    </>
  )
})

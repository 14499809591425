import React from "react"

import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"

import { useGameActor, useT } from "@hooks"

import { Frame } from "@gameDom"
import * as styles from "./index.module.scss"

export default React.memo(function ShotFailed() {
  const [levelState] = useGameActor("levelMachineRef")
  const isActive = levelState.matches("outro.fail")

  const t = useT("game")

  const transitions = useTransition(isActive, {
    from: { opacity: 0, scale: 0.9, x: "-50%" },
    enter: { opacity: 1, scale: 1, x: "-50%" },
    leave: { opacity: 0, scale: 0.9, x: "-50%" },
    delay: isActive ? 700 : 0,
    config: {
      mass: 2,
      tension: 351,
    },
  })

  return (
    <>
      {transitions(
        (springStyles, item) =>
          item && (
            <a.div className={styles.root} style={springStyles}>
              <Frame>
                <div className={styles.title}>{t("game.failed.title")} </div>
                <div className={styles.description}>{t("game.failed.description")} </div>
              </Frame>
            </a.div>
          ),
      )}
    </>
  )
})

import React, { useEffect, useState } from "react"
import { a } from "@react-spring/three"

import { useGameMachine, useGameActor, useGameAsset } from "@hooks"

import { config } from "@gameData"

import Single from "./Single"

export default React.memo(function VisualTarget() {
  const [gameState] = useGameMachine()
  const [levelState] = useGameActor("levelMachineRef")
  const [position, setPosition] = useState([0, 1])
  const isActive = !(levelState.matches("disable") || levelState.matches("intro"))

  const mapCenter = useGameAsset("target-center")
  const mapMiddle = useGameAsset("target-mid")
  const mapEdge = useGameAsset("target-edge")

  const { goal, misc } = config
  const { size } = goal

  const { currentLevel, levels } = gameState.context
  const data = levels[currentLevel]

  useEffect(() => {
    if (levelState.matches("playing.initialCountdown")) {
      let p = [(data.game.target.x - 0.5) * size.width, data.game.target.y * size.height]

      setPosition(p)
      levelState.context.targetPosition = p
    }
  }, [levelState.value])

  return (
    <a.group position-x={position[0]} position-y={position[1]} position-z={-misc.shot.distance}>
      <Single map={mapCenter} i={0} isActive={isActive} />
      <Single map={mapMiddle} i={1} isActive={isActive} />
      <Single map={mapEdge} i={2} isActive={isActive} />
    </a.group>
  )
})

import { useSpring } from "@react-spring/core"
import { useDrag } from "react-use-gesture"

import { useGameActor } from "@hooks"

import { config } from "@gameData"

export default function useAiming({ bounds, onEndCb }) {
  const { aim, goal } = config

  const [state, send] = useGameActor("levelMachineRef")

  const [{ x, y }, set] = useSpring(() => ({
    config: {
      mass: 16,
      friction: 120,
    },
    x: 0,
    y: 0,
    scale: 1,
    onChange: () => {
      let tx = x.get()
      let ty = y.get()
      state.context.aimPosition[0] = tx
      state.context.aimPosition[1] = ty

      state.context.normalizedAimPosition[0] = tx / (aim.bounds.width * 0.5)
      state.context.normalizedAimPosition[1] = ty / aim.bounds.height
    },
  }))

  const onDragStart = () => {
    if (!state.matches("playing.idle")) {
      return
    }

    send("AIMING")
    set({
      x: 0,
      y: 0,
      immediate: true,
    })
    set({
      scale: 1,
      immediate: false,
    })
  }

  const onDragEnd = () => {
    if (state.matches("playing.aiming")) {
      send("SHOOTING")
      onEndCb()
      set.stop(true)

      set({
        scale: 0,
      })
    }
  }

  const bind = useDrag(
    ({ movement: [ox, oy], last, first }) => {
      if (state.matches("disable")) {
        return
      }

      if (last) {
        onDragEnd()
        return
      }

      if (first) {
        onDragStart()
      }

      let dx = -(ox / bounds[0]) * (aim.bounds.width * 0.5)
      let dy =
        (oy / bounds[1]) * (aim.bounds.height * 0.5) +
        aim.bounds.height * 0.5 +
        goal.size.floorShift

      // MOVE THE AIM WITH A MASS
      set({
        x: dx,
        y: dy,
      })
    },
    {
      bounds: { left: -bounds[0], right: bounds[0], top: -bounds[1], bottom: bounds[1] },
    },
  )

  return [bind]
}

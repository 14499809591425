import React, { useEffect } from "react"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import * as styles from "./index.module.scss"

import { useGameMachine, useGameActor, useT } from "@hooks"

import { Cta } from "@gameDom"

export default React.memo(function EndLevel() {
  const [gameState] = useGameMachine()
  const [levelState, sendToLevel] = useGameActor("levelMachineRef")

  const isActive = levelState.matches("outro.fail")

  const t = useT("game")

  const onClickRetry = () => {
    sendToLevel("RESTART")
  }

  const transitions = useTransition(isActive, {
    from: { opacity: 0, scale: 0.9 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.9 },
    delay: isActive ? 900 : 0,
    config: {
      mass: 3,
      tension: 451,
    },
  })

  return (
    <>
      {transitions(
        (springStyles, item) =>
          item && (
            <div className={styles.root}>
              <a.div style={springStyles}>
                <Cta onClick={onClickRetry}>{t("game.failed.retry")}</Cta>
              </a.div>
            </div>
          ),
      )}
    </>
  )
})

import React, { useMemo } from "react"

import { useGameMachine, useT } from "@hooks"

import Single from "./Single"

export default React.memo(function Moments() {
  const [state] = useGameMachine()
  const t = useT("game")

  const { currentLevel } = state.context
  const levelsData = t("levels")

  const nodes = useMemo(() => {
    return levelsData.map((el, i) => {
      return i > 0 && <Single key={i} data={el} isCurrent={currentLevel === i} />
    })
  }, [currentLevel])

  return <>{nodes}</>
})

import React, { useRef, useState, useEffect } from "react"
import { useChain, useSpring, useSpringRef } from "@react-spring/core"
import { a } from "@react-spring/web"

import * as styles from "./index.module.scss"

import { useGameContext, useGameMachine, useT } from "@hooks"

import { OverlayLayer, Cta, Frame } from "@gameDom"
import LevelsIndicator from "./LevelsIndicator"

const config = {
  mass: 2,
  tension: 200,
}

export default React.memo(function Landing() {
  const [state, send] = useGameMachine()
  const { audios } = useGameContext()
  const [isActive, setIsActive] = useState(false)

  const backgroundAudio = audios.get("background")

  const t = useT("game")

  const isFirstClick = useRef(true)

  const { currentLevel } = state.context
  const content = currentLevel > 0 ? t("landing.game") : t("landing.intro")

  const onClick = () => {
    if (isFirstClick.current) {
      backgroundAudio.play()
      isFirstClick.current = false
    }

    send("START_PLAYING")
  }

  // HANDLE THE ACTIVE STATE BECAUSE OF THE INITIAL LOADING
  useEffect(() => {
    if (state.matches("selecting")) {
      setTimeout(
        () => {
          setIsActive(true)
        },
        state?.history?.matches("loaded") ? 2000 : 0,
      )
    } else {
      setIsActive(false)
    }
  }, [state.value])

  const springRef = useSpringRef()
  const spring = useSpring({
    ref: springRef,
    opacity: isActive ? 1 : 0,
    delay: 200,
  })

  const topSpringRef = useSpringRef()
  const topSpring = useSpring({
    ref: topSpringRef,
    opacity: isActive ? 1 : 0,
    scale: isActive ? 1 : 0.8,
    config,
  })

  const levelsSpringRef = useSpringRef()
  const levelsSpring = useSpring({
    ref: levelsSpringRef,
    opacity: isActive ? 1 : 0,
    scale: isActive ? 1 : 0.95,
    config,
  })

  const ctaSpringRef = useSpringRef()
  const ctaSpring = useSpring({
    ref: ctaSpringRef,
    opacity: isActive ? 1 : 0,
    config,
  })

  const order = isActive
    ? [springRef, topSpringRef, levelsSpringRef, ctaSpringRef]
    : [levelsSpringRef, topSpringRef, ctaSpringRef, springRef]
  const timing = isActive ? [0.2, 0.8, 1.2, 1.6] : [0.3, 0.3, 0.3, 0.6]

  useChain(order, timing)

  return (
    <>
      <OverlayLayer
        styles={{
          ...spring,
          visibility: spring.opacity.to((v) => (v === 0 ? "hidden" : "visible")),
        }}
      >
        <div className={styles.root}>
          <a.div style={topSpring}>
            <Frame>
              <h1 className={styles.title}>{content.title}</h1>
            </Frame>
          </a.div>
          <LevelsIndicator spring={levelsSpring} />
          <Cta spring={ctaSpring} onClick={onClick}>
            {content.cta}
          </Cta>
        </div>
      </OverlayLayer>
    </>
  )
})

import React, { useRef, useState, useEffect } from "react"

import config from "./config.yml"

import SpotLight from "./SpotLight"

export default React.memo(function Lights() {
  return (
    <>
      <SpotLight data={config[0]} />
      <SpotLight data={config[1]} />
      <SpotLight data={config[2]} />
    </>
  )
})

/**
 * ORIGINAL HERE BELOW TEMPORARY, I'LL DELATE IT LATER
 */

// import React, { useRef, useState, useEffect } from "react"
// import { useControls } from "leva"

// import { useGameContext } from "@hooks"

// export default React.memo(function Lights() {
//   const { isDebugMode } = useGameContext()
//   const [forceRender, setForceRender] = useState(0.123)
//   const t1 = useRef()
//   const t2 = useRef()
//   const t3 = useRef()

//   const sp1Ref = useRef()
//   const sp2Ref = useRef()
//   const sp3Ref = useRef()

//   // const dir = useControls("directional", {
//   //   position: [5, 10, 7.5],
//   //   intensity: { value: 0.4, min: 0, max: 3 },
//   // })

//   const tar1 = useControls("target-sp-1-ball", {
//     position: [0.5, 0, -1],
//   })

//   const tar2 = useControls("target-sp-2-ball", {
//     position: [0.7, 0, -1],
//   })

//   const tar3 = useControls("target-sp-3-goal", {
//     position: [0, 4.5, -12],
//   })

//   const sp1 = useControls("spotlight-1-ball", {
//     position: [4.5, 5, 9],
//     intensity: { value: 1.38, min: 0, max: 3 },
//     penumbra: { value: 0.6, min: 0, max: 1 },
//     angle: { value: 0.65, min: 0, max: 1 },
//   })

//   const sp2 = useControls("spotlight-2-ball", {
//     position: [-4.5, 5, 9],
//     intensity: { value: 1.38, min: 0, max: 3 },
//     penumbra: { value: 0.6, min: 0, max: 1 },
//     angle: { value: 0.65, min: 0, max: 1 },
//   })

//   const sp3 = useControls("spotlight-3-goal", {
//     position: [0, 20, 7.5],
//     intensity: { value: 2.5, min: 0, max: 3 },
//     penumbra: { value: 0.54, min: 0, max: 1 },
//     angle: { value: 0.4, min: 0, max: 1 },
//   })

//   useEffect(() => {
//     setForceRender(Math.random())
//   }, [])

//   return (
//     <>
//       <object3D ref={t1} {...tar1} />
//       <object3D ref={t2} {...tar2} />
//       <object3D ref={t3} {...tar3} />

//       {/* <directionalLight {...dir} /> */}

//       <spotLight ref={sp1Ref} {...sp1} castShadow={true} target={t1.current} />
//       <spotLight ref={sp2Ref} {...sp2} castShadow={true} target={t2.current} />
//       <spotLight ref={sp3Ref} {...sp3} castShadow={true} target={t3.current} />

//       {isDebugMode && (
//         <>
//           {sp1Ref.current && <spotLightHelper args={[sp1Ref.current]} />}
//           {sp2Ref.current && <spotLightHelper args={[sp2Ref.current]} />}
//           {sp3Ref.current && <spotLightHelper args={[sp3Ref.current]} />}
//         </>
//       )}
//     </>
//   )
// })

// // t1 {"position":[0.5,4,-5]}
// // t2 {"position":[0.7,4,-5]}
// // t3 {"position":[0,4.5,-5]}

// // sp1
// // {"position":[4.5,9.019999999999987,0]}
// // {"intensity":0.7799999999999999}
// // {"penumbra":1}
// // {"angle":0.9}

// // sp2
// // {"position":[-4.5,9,0]}
// // {"intensity":0.8099999999999999}
// // {"penumbra":1}
// // {"angle":0.9}

// // sp3
// // {"position":[0,20,-5]}
// // {"intensity":1.69}
// // {"penumbra":0.4}
// // {"angle":0.30000000000000004}

import React from "react"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"

import * as styles from "./index.module.scss"

import { useGameMachine, useT } from "@hooks"

import { Frame } from "@gameDom"
import { Html } from "@dom"

import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share"

export default React.memo(function EndGame() {
  const [gameState] = useGameMachine()
  const t = useT("game")

  const isActive = gameState.matches("gameWon")

  const transitions = useTransition(isActive, {
    from: { opacity: 0, scale: 0.9, x: "-50%" },
    enter: { opacity: 1, scale: 1, x: "-50%" },
    leave: { opacity: 0, scale: 0.9, x: "-50%" },
    delay: isActive ? 1900 : 0,
    config: {
      mass: 2,
      tension: 351,
    },
  })

  return (
    <>
      {transitions(
        (spring, item) =>
          item && (
            <a.div style={spring} className={styles.root}>
              <Frame>
                <Html>{t("game.won")}</Html>
              </Frame>
              <div className={styles.share}>
                <FacebookShareButton
                  url={`https://concacaf60.com/game/`}
                  quote={"Concacaf60 - Skills Challenge"}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`https://concacaf60.com/game/`}
                  title={"Concacaf60 - Skills Challenge"}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </a.div>
          ),
      )}
    </>
  )
})

import React, { useEffect } from "react"

import { useGameAsset } from "@hooks"

export default React.memo(function Field() {
  const { scene } = useGameAsset("field")

  useEffect(() => {
    scene.traverse((el) => {
      if (el.isMesh) {
        el.receiveShadow = true
      }

      if (el.name === "stk1") {
        // GOAL NET
        el.renderOrder = 0
        el.needsUpdate = true
      }
    })
  }, [])

  return <primitive object={scene} />
})

import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"

import { useGameMachine } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

import { css } from "@jsUtils"
import * as styles from "./index.module.scss"
const IMAGES = {
  completed: "/images/game/levels/level-completed.png",
  incomplete: "/images/game/levels/level-incomplete.png",
  current: "/images/game/levels/level-current.png",
}

export default React.memo(function Single({ data, id }) {
  const goldLine = useRef()
  const iconIncomplete = useRef()
  const iconCurrent = useRef()
  const iconDone = useRef()
  const [state] = useGameMachine()

  const { levels, currentLevel } = state.context
  //   const { status } = levels[id]

  const isCurrent = currentLevel === id
  const isLast = id === levels.length - 1
  const isPrevious = id === currentLevel - 1
  const isOnboarding = currentLevel === 0

  useEffect(() => {
    if (state.matches("selecting")) {
      // ANIMATE DONE ICON + PROGRESS LINE
      if (isPrevious) {
        let tl = gsap.timeline({ delay: 2 })
        tl
          //
          .to(iconDone.current, { duration: 1.2, scale: 1, ease: "elastic.out(1, 0.5)" })
          .to(iconCurrent.current, { duration: 1.2, scale: 0, ease: "elastic.out(1, 0.5)" }, 0.1)
          .to(goldLine.current, { duration: 1, scaleX: 1, scaleY: 1 })
      } else if (isCurrent) {
        // ANIMATE CURRENT ICON
        const delay = isOnboarding ? 3.6 : 3.4
        let tl = gsap.timeline({ delay })

        tl
          //
          .to(iconCurrent.current, {
            duration: 1.2,
            delay: 1,
            scale: 1,
            ease: "elastic.out(1, 0.5)",
          })
      }
    }
  }, [state])

  return (
    <>
      <div className={styles.single}>
        <div className={styles.icon}>
          <img ref={iconIncomplete} src={IMAGES["incomplete"]} />
          <img ref={iconCurrent} src={IMAGES["current"]} />
          <img ref={iconDone} src={IMAGES["completed"]} />
        </div>

        <div className={styles.infoWrapper}>
          <div className={styles.year}>{data.year}</div>
          <div className={styles.teams}>
            &nbsp;
            <span>{data.teams}</span>
          </div>
        </div>
      </div>

      {!isLast && (
        <div className={css(styles.spacer)}>
          <div className={styles.line} />
          <div ref={goldLine} className={css(styles.line, styles.lineProgress)} />
        </div>
      )}
    </>
  )
})

import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"

import { useGameMachine, useGameActor } from "@hooks"

export default React.memo(function SpotLight({ data }) {
  const { opening, basic, end } = data

  const [state] = useGameMachine()
  const [levelState] = useGameActor("levelMachineRef")

  const targetRef = useRef()

  const light = useRef()

  useEffect(function onMount() {
    const { target, params } = opening

    targetRef.current.position.fromArray(target.position)
    light.current.position.fromArray(params.position)

    light.current.intensity = params.intensity
    light.current.penumbra = params.penumbra
    light.current.angle = params.angle
  }, [])

  const onOpening = () => {
    let tl = gsap.timeline({ delay: opening.params.delay * 1.4 + 0.5 })

    const { params } = basic
    const duration = 3

    tl
      //
      .to(light.current, { duration, intensity: params.intensity })
      .to(
        light.current.position,
        {
          duration: 2,
          x: params.position[0],
          y: params.position[1],
          z: params.position[2],
          ease: "Power3.easeInOut",
        },
        0.3,
      )
  }

  const onGameWon = () => {
    const duration = 2.4
    const ease = "Power3.easeInOut"
    const { target, params } = end
    const { intensity, penumbra, angle } = params

    let tl = gsap.timeline({ delay: 0.2 })

    tl.to(targetRef.current.position, {
      duration,
      x: target.position[0],
      y: target.position[1],
      z: target.position[2],
      ease,
    })
      .to(
        light.current.position,
        { duration, x: params.position[0], y: params.position[1], z: params.position[2], ease },
        0,
      )
      .to(light.current, { duration, intensity, penumbra, angle, ease }, 0)
  }

  const onCelebrating = () => {
    let tl = gsap.timeline({ delay: 0.2 })

    tl
      //
      .to(light.current, {
        duration: 0.35,
        intensity: "+=0.3",
        angle: "+=0.02",
        repeat: 5,
        yoyo: true,
        ease: "Power1.easeInOut",
      })
      .to(
        light.current.position,
        {
          duration: 0.35,
          z: "+=5",
          repeat: 5,
          yoyo: true,
          ease: "Power1.easeInOut",
        },
        0,
      )
  }

  const onFail = () => {
    let tl = gsap.timeline({ delay: 0.235 })

    tl
      //
      .to(light.current, {
        duration: 0.75,
        intensity: light.current.intensity * 0.35,
        angle: "-=0.05",
        ease: "Power1.easeInOut",
      })
  }

  const onAfterFail = () => {
    let tl = gsap.timeline({ delay: 0.2 })

    tl
      //
      .to(light.current, {
        duration: 0.5,
        intensity: basic.params.intensity,
        angle: "+=0.05",
        ease: "Power1.easeInOut",
      })
  }

  useEffect(() => {
    if (state.matches("opening.animating")) {
      onOpening()
    }

    if (state.matches("gameWon")) {
      onGameWon()
    }
  }, [state])

  useEffect(() => {
    if (levelState.matches("outro.celebrating")) {
      onCelebrating()
    }
    if (levelState.matches("outro.fail")) {
      onFail()
    }

    if (
      levelState.matches("playing.initialCountdown") &&
      levelState?.history?.matches("outro.fail")
    ) {
      onAfterFail()
    }
  }, [levelState])

  return (
    <>
      <object3D ref={targetRef} />
      <spotLight ref={light} castShadow={true} target={targetRef.current} />
    </>
  )
})
